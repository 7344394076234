import routes from 'config/routes'
import * as Pages from 'pages'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

function App() {
  return (
    <Switch>
      <Route exact path={routes.homepage} component={Pages.Main} />
      <Route exact path={routes.privacy} component={Pages.Privacy} />
      <Route exact path={routes.refunds} component={Pages.Refunds} />
      <Route exact path={routes.terms} component={Pages.Terms} />
      <Redirect to={routes.homepage} />
    </Switch>
  )
}

export default App
