import { Footer } from 'components'
import routes from 'config/routes'
import brandLogo from 'images/igiv-logo-small.png'
import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function AppLayout({ children }) {
  return (
    <>
      <Navbar bg="light" expand="lg" className="border-0">
        <Container>
          <Link to={routes.homepage}>
            <Navbar.Brand>
              <img src={brandLogo} height={40} alt="igiv logo" />
            </Navbar.Brand>
          </Link>
        </Container>
      </Navbar>
      <main>{children}</main>
      <Footer />
    </>
  )
}
