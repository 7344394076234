import { MailTo } from 'components'
import React from 'react'

export function RefundTerms() {
  const email = 'support@igiv.org'
  const refundMessage = `Please answer the following questions to help us address your request as quickly as possible.

1. What was your order number?

2. What was the email address provided?

3. What was the name of the fundraiser you donated to?

5. Why are you requesting a refund?
`
  const fraudMessage = `Please answer the following questions to help us address your request as quickly as possible.

1. What is your name?

2. What would you like to report? (Be as detailed as possible)

`

  return (
    <div>
      <h2>
        <span>Requesting a Refund</span>
      </h2>
      <ol start="37">
        <li>
          We understand that on occasion, a purchase may have been made in
          error. If you would like to request a Refund for a purchase that you
          have made recently made through the IGIV Platform, please contact us
          through the following link:{' '}
          <MailTo
            title="Refund Request"
            recipient={email}
            options={{
              subject: `Refund Request`,
              body: refundMessage,
            }}
          />
        </li>
        <li>
          Please note, purchases that are made between the 1st - 15th of each
          month can only be refunded up until the 20th of the same month.
          Purchases that are made between the 16th - Last Day of the Month can
          only be refunded until the 5th of the following month. Product
          received in exchange for the purchase you made, must be returned in
          its original, unused condition. After this period of time, funds are
          sent for processing and are no longer available for us to refund. If
          your purchase does not fall within the time-frame, you will need to
          contact the organization you are supporting directly to obtain a
          refund.
        </li>
        <li>
          <span>
            If you believe that your credit card was used without your
            authorization or that the funds earned through your purchase was
            misused or that the IGIV campaign organizer or beneficiary committed
            fraud, please go to the following link and submit a claim:{' '}
            <MailTo
              title="Fraud Claim"
              recipient={email}
              options={{
                subject: `Fraud Claim`,
                body: fraudMessage,
              }}
            />
          </span>
        </li>
      </ol>
    </div>
  )
}

export default RefundTerms
