import { ScrollToTop } from 'components'
import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './styles/index.scss'

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale="en" defaultLocale="en">
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
