import { RefundTerms } from 'components'
import { AppLayout } from 'layouts'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export function Terms() {
  return (
    <AppLayout>
      <div className="bg-white">
        <Container className="pt-5">
          <Row>
            <Col lg={8} md={10} className="mx-auto">
              <h1>Terms and Conditions</h1>
              <p>
                <span className="font-italic">
                  Last revised on: 23 February 2021
                </span>
              </p>
              <p>
                <span>
                  Igiv.org (referred to in this document as &quot;us&quot;,
                  &quot;we&quot;, &quot;our&quot;, &ldquo;IGIV&rdquo; etc.) is
                  the owner and operator of the www.igiv.org website, an online
                  commerce platform for organizations, including nonprofit
                  organizations to assist with their online transaction support.
                  These Terms of Use (&ldquo;Terms&rdquo;) apply to the{' '}
                </span>
                <span>www.igiv.org</span>
                <span>
                  &nbsp;website, any subdomains and any other website or
                  webpages we own or operate that include a link to these Terms
                  (together, the &quot;Site&quot;). These Terms apply to all
                  users of the Site and services in whatever capacity, including
                  non-registered users, users with a registered account,
                  supporters of specific campaigns, campaign organizers, and
                  nonprofits. By accessing, using or registering an account with
                  the Site, or by using any of our services offered through the
                  Site, you agree to be bound by these Terms.
                </span>
              </p>
              <h2>
                <span>Updates To These Terms</span>
              </h2>
              <ol start="1">
                <li>
                  <span>
                    We reserve the right, at our sole discretion, to change or
                    modify portions of these Terms of Use at any time. If we do
                    this, we will post the changes on this page and indicate the
                    date of the latest revision at the top of this page. We
                    shall also endeavour to notify you, either by email, through
                    the Site user interface, or other reasonable means. All such
                    changes shall become effective immediately when they are
                    posted. Your continued use of the Site and/or services. If
                    you do not wish to use the Site or services in accordance
                    with the Terms, then you should immediately discontinue
                    using the Site or service. Continued use constitutes your
                    acceptance of the new Terms.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Status of Platform and Disclaimers</span>
              </h2>
              <ol start="2">
                <li>
                  <span>
                    The Site and the services offered are a platform; we are not
                    a broker, financial institution, creditor or charitable
                    institution. The services and Site are an administrative
                    platform only. IGIV facilitates transactions between Users
                    and Organizations, &nbsp;but is not a party to any agreement
                    between a User and a Nonprofit, Campaign Organizer or
                    Organization. IGIV has no control over the conduct of, or
                    any information provided by, a Campaign Organizer, Nonprofit
                    &nbsp;or Organization, and IGIV hereby disclaims all
                    liability in this regard to the fullest extent permitted by
                    applicable law
                  </span>
                </li>
                <li>
                  <span>
                    IGIV does not guarantee that a campaign will make a certain
                    amount of sales or obtain a certain amount of support or any
                    support at all. We do not personally endorse any campaign,
                    Campaign Organizer, or Nonprofit, and we make no guarantee,
                    explicit or implied, that any information provided through
                    the services or Site by any user is accurate. We expressly
                    disclaim any liability or responsibility for the success of
                    any campaign, or the outcome of any fundraising purpose.
                    You, as a User, must make the final determination as to the
                    value and appropriateness of contributing to any campaign,
                    Campaign Organizer, or Nonprofit.
                  </span>
                </li>
                <li>
                  <span>
                    We do not and cannot verify the information that Campaign
                    Organizers supply, nor do we guarantee that the financial
                    support will be used in accordance with any specific purpose
                    prescribed by a Campaign Organizer or Nonprofit. We assume
                    no responsibility to verify whether the support is used in
                    accordance with any applicable laws; such responsibility
                    rests solely with the campaign Organizer or
                    Nonprofitcharity, as applicable. While we have no obligation
                    to verify that the use of any funds raised is in accordance
                    with applicable law and these Terms, we take possible
                    fraudulent activity and the misuse of funds raised very
                    seriously. If you have reason to believe that a Campaign
                    Organizeror Nonprofit is not raising or using the funds for
                    their stated purpose, please alert us as immediately (see
                    section 39).
                  </span>
                </li>
                <li>
                  <span>
                    IGIV reserves the right to modify, suspend or discontinue,
                    temporarily or permanently, the Site and/or our services (or
                    any part thereof). We will endeavor to provide notice of any
                    such modification, suspension or discontinuance. You agree
                    that, to the extent permitted by law, IGIV is not liable to
                    you for any modification, suspension or discontinuance of
                    the Services in accordance with these Terms.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Registration</span>
              </h2>
              <ol start="6">
                <li>
                  <span>
                    You may be required to register with IGIV in order to access
                    and use certain features of the Site and our services. If
                    you choose to register, you agree to provide and maintain
                    true, accurate, current and complete information about
                    yourself as prompted by the Services&#39; registration form.
                    Campaign Organizers must register using their true
                    identities, including their name and any image or video
                    purporting to depict the Campaign Organizer.
                  </span>
                </li>
                <li>
                  <span>
                    Registration data and certain other information about you
                    are governed by our{' '}
                  </span>
                  <span>Privacy Policy</span>
                  <span>
                    . If you are under 13 years of age, you are not authorized
                    to use the Services, with or without registering. In
                    addition, if you are under the age of majority in your
                    jurisdiction (typically 18 or 19 years of age), you may use
                    the Site and our services, with or without registering, only
                    with the approval of your parent or guardian.
                  </span>
                </li>
                <li>
                  <span>
                    You are responsible for maintaining the confidentiality of
                    your password and account, if any, and are fully responsible
                    for any and all activities that occur under your password or
                    account. You agree to immediately notify IGIV of any
                    unauthorized use of your password or account or any other
                    breach of security and ensure that you sign out from your
                    account at the end of each session when accessing the Site.
                    IGIV is not liable for any loss or damage arising from your
                    failure to comply with these requirements.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Third-party Payment Providers</span>
              </h2>
              <ol start="9">
                <li>
                  <span>
                    Certain aspects of our Site or services may require you to
                    register with, and agree to the terms of, third-party
                    service providers (e.g. payment processors) in order to
                    utilize the services. For example, Stripe is one of
                    IGIV&rsquo;s payment processing partners. When you use
                    Stripe&rsquo;s services, your right to use such services is
                    strictly confidential for the purpose you provide in the
                    details of your registration. You may not resell, hire, or
                    on any other basis, allow third parties to use the payment
                    services to enable such third parties to be paid for their
                    services. You may not use the payment services for any
                    different purpose than as registered with our application.
                    IGIV reserves the right to suspend or terminate your access
                    to the Site or our services immediately. Further, services
                    may be suspended and/or terminated with immediate effect and
                    fines may be applied by credit card agencies and/or
                    authorities will be payable by you.
                  </span>
                </li>
                <li>
                  <span>
                    IGIV will assist you in using our authorised third-party
                    payment processing services; you understand and agree to
                    Stripe or other third-party payment providers to provide
                    IGIV with access to your data and settings in their systems,
                    and authorize us to manage these on your behalf, and
                    authorise us to exchange your information with the providers
                    for the purpose of facilitating the provision of the Site
                    and our services. Where any information consists of your
                    personal information covered by our{' '}
                  </span>
                  <span>Privacy Policy</span>
                  <span>
                    , it shall only be shared in accordance with that Policy.
                  </span>
                </li>
                <li>
                  <span>
                    While we facilitate your registration and access to our
                    third-party payment partners, we are not a party to your
                    relationship with any partner and disclaim any
                    responsibility or liability for their performance.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Ownership; Intellectual Property</span>
              </h2>
              <ol start="12">
                <li>
                  <span>
                    The technology and software underlying the Site and services
                    or distributed in connection with it are the property of
                    IGIV, our affiliates and our partners (the
                    &quot;Software&quot;).
                  </span>
                </li>
                <li>
                  <span>
                    You acknowledge and agree that the Site and services may
                    contain content or features (&quot;Site Content&quot;) that
                    are protected by copyright, patent, trademark, trade secret
                    or other proprietary rights and laws. You understand and
                    agree that your use of the Site, services, or Site Content
                    in no way gives you a right, title, or interest in the Site,
                    Site Content or our intellectual property.
                  </span>
                </li>
                <li>
                  <span>
                    The IGIV name and logos are trademarks and service marks of
                    IGIV (collectively the &quot;IGIV Trademarks&quot;). Other
                    company, product, and service names and logos used and
                    displayed via the Site and services may be trademarks or
                    service marks of their respective owners, who may or may not
                    endorse or be affiliated with or connected to IGIV. Nothing
                    in these Terms or the Site should be construed as granting,
                    by implication, estoppel, or otherwise, any license or right
                    to use any of the IGIV Trademarks displayed on the Site,
                    without our prior written permission in each instance. All
                    goodwill generated from the use of the IGIV Trademarks will
                    insure to our exclusive benefit. &nbsp;IGIV will process and
                    investigate notices of alleged infringement and will take
                    appropriate actions under the Digital Millennium Copyright
                    Act (&quot;DMCA&quot;) and other applicable intellectual
                    property laws with respect to any alleged or actual
                    infringement. A notification of claimed copyright
                    infringement should be emailed to:{' '}
                  </span>
                  <span>support@igiv.org </span>
                  <span>
                    (Subject line: &quot;DMCA Takedown Request&quot;).
                  </span>
                </li>
              </ol>
              <h2>
                <span>Acceptable Use; Prohibited Activities</span>
              </h2>
              <ol start="15">
                <li>
                  <span>
                    You agree to use the Site and the features and services
                    provided through the Site only as they are obviously
                    intended to be used. All other uses are strictly prohibited.
                  </span>
                </li>
                <li>
                  <span>
                    Except as expressly authorized by IGIV, you agree not to
                    modify, copy, frame, scrape, rent, lease, loan, sell,
                    distribute or create derivative works based on the Site, the
                    services or Site Content, in whole or in part, except that
                    the foregoing does not apply to your own uploaded user
                    content that you legally upload to the Site.
                  </span>
                </li>
                <li>
                  <span>
                    You agree that in connection with your use of the Site and
                    our services, you will not engage in or use any data mining,
                    robots, scraping or similar data gathering or extraction
                    methods. If you are blocked by IGIV from accessing the Site
                    or the services (including by blocking your IP address), you
                    agree not to implement any measures to circumvent such
                    blocking. Any use of the Site, services or Site Content
                    other than as specifically authorized herein is strictly
                    prohibited.
                  </span>
                </li>
                <li>
                  <span>
                    You agree not to (or cause or enable anyone to) copy,
                    modify, create a derivative work of, reverse engineer,
                    reverse assemble or otherwise attempt to discover any source
                    code, sell, assign, sublicense, or otherwise transfer any
                    right in the Software. Any rights not expressly granted
                    herein are reserved by IGIV
                  </span>
                </li>
              </ol>
              <h2>
                <span>User Content</span>
              </h2>
              <ol start="19">
                <li>
                  <span>
                    In relation to all content, photos, videos, images,
                    trademarks, logos, brands or other materials you upload or
                    post through the Site or share with other users or
                    recipients, you represent and warrant that you own all
                    right, title and interest in and to, or otherwise have all
                    necessary rights and consents to (and to allow others to)
                    fully exploit same, including, without limitation, as it
                    concerns all copyrights, trademark rights and rights of
                    publicity or privacy related thereto.
                  </span>
                </li>
                <li>
                  <span>
                    By uploading any of the above, you hereby grant and will
                    grant IGIV and its affiliated companies a nonexclusive,
                    worldwide, royalty free, fully paid up, transferable,
                    sublicensable, perpetual, irrevocable license to copy,
                    display, upload, perform, distribute, store, modify and
                    otherwise use same in connection with the operation of the
                    Site or the promotion, advertising or marketing thereof, in
                    any form, medium or technology now known or later developed.
                  </span>
                </li>
                <li>
                  <span>
                    Further, if any person (other than you) appears in your
                    uploaded content, you represent and warrant that you have
                    secured all necessary licenses, waivers and releases from
                    such person(s) for the benefit of IGIV in a manner fully
                    consistent with the licenses, waivers and releases set forth
                    above.
                  </span>
                </li>
                <li>
                  <span>
                    Under no circumstances will IGIV be liable in any way for
                    any content or materials of any third parties (including
                    users, Nonprofits and Campaign Organizers), including, but
                    not limited to, for any errors or omissions in any content,
                    or for any loss or damage of any kind incurred as a result
                    of the use of any such content. You acknowledge that IGIV
                    does not pre-screen content. IGIV reserves the right to
                    remove or alter any content or materials from third parties
                    at its sole discretion, including the right to remove any
                    content that violates these Terms or is deemed by IGIV, in
                    its sole discretion, to be otherwise objectionable.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Third-Party Websites and Services</span>
              </h2>
              <ol start="23">
                <li>
                  <span>
                    The Site may provide or facilitate, or third parties may
                    provide, links or other access to other sites, services and
                    resources on the Internet. IGIV has no control over such
                    sites, services and resources and IGIV is not responsible
                    for and does not endorse such sites, services and resources.
                    You further acknowledge and agree that IGIV will not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in
                    connection with use of or reliance on any content, events,
                    goods or services available on or through any such site,
                    service or resource. Any dealings you have with third
                    parties found while using the Site are between you and the
                    third party, and you agree that IGIV is not liable for any
                    loss or claim that you may have against any such third party
                  </span>
                </li>
              </ol>
              <h2>
                <span>General User Warranties</span>
              </h2>
              <ol start="24">
                <li>
                  <span>
                    By accessing, using, or registering with the Site or
                    accessing our services you represent, warrant, and
                    irrevocably covenant that:
                  </span>
                </li>
                <ol start="1">
                  <li>
                    <span>
                      You have the authority to enter into these Terms;
                    </span>
                  </li>
                  <li>
                    <span>
                      Your decision to enter into these Terms and your use of
                      the Site will not violate any applicable law, regulation,
                      or ordinance;
                    </span>
                  </li>
                  <li>
                    <span>
                      Your decision to enter into these Terms and your use of
                      the Site will not infringe the rights of any third
                      parties;
                    </span>
                  </li>
                  <li>
                    <span>
                      You will at all times supply truthful and accurate
                      information to us and you will not misrepresent yourself
                      to the public through your use of the Site;
                    </span>
                  </li>
                  <li>
                    <span>
                      You will never use the Site, or any services we provide to
                      you, in a manner that violates the law or the legal rights
                      of a third party; and
                    </span>
                  </li>
                  <li>
                    <span>
                      You are at least 13 years of age if you are using the Site
                      and have the consent of a parent or legal guardian if you
                      are under 18 years of age.
                    </span>
                  </li>
                </ol>
              </ol>
              <h2>
                <span>Additional Policies</span>
              </h2>
              <ol start="25">
                <li>
                  <span>Privacy Policy.</span>
                  <span>
                    &nbsp;We take your privacy seriously. Please see our
                    detailed{' '}
                  </span>
                  <span>Privacy Policy</span>
                  <span>
                    &nbsp;for more information on how we store, protect, use and
                    disclose your information. By using the Site, you consent to
                    our collection and use of personal data as outlined in this
                    Policy.
                  </span>
                </li>
              </ol>
              <ol start="26">
                <li>
                  <span>Standards of Conduct.</span>
                  <span>
                    You agree to abide by our Standards of Conduct, which are
                    material terms of your use of the Site.
                  </span>
                </li>
              </ol>
              <h2>
                <span>International Use</span>
              </h2>
              <ol start="27">
                <li>
                  <span>
                    This Site is not intended for use by organizations organized
                    under the laws of countries other than those of the United
                    States of America and Canada. You represent, warrant, and
                    irrevocably covenant that you will refrain from making
                    financial transactions through the Site if you (i) are
                    located in a country embargoed by the United States or (ii)
                    are on the U.S. Treasury Department&#39;s list of Specially
                    Designated Nationals. If you choose to use this Site, you
                    are solely responsible for compliance with all applicable
                    local laws and you consent to having your data transferred,
                    processed, and stored in the United States, and, in some
                    cases, Canada.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Fees</span>
              </h2>
              <ol start="28">
                <li>
                  <span>
                    Fees vary based upon campaign type, product type and
                    campaign organizer arrangement with organization.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Taxes</span>
              </h2>
              <ol start="29">
                <li>
                  <span>
                    You understand that the party you transact with through the
                    Site is the one that sets the tax language in any receipt
                    you receive. We make no representations about the nature of
                    any transaction you make through the Site. It is your
                    responsibility to determine what, if any, taxes apply to any
                    donations you receive through your use of the Site. It is
                    solely your responsibility to assess, collect, report or
                    remit the correct tax, if any, to the appropriate tax
                    authority. Always consult the organization you are
                    transacting with and a qualified financial advisor prior to
                    claiming a deduction on your taxes.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Disclaimer of Warranties</span>
              </h2>
              <ol start="30">
                <li>
                  <span>
                    YOUR USE OF THE SITE AND OUR SERVICES IS AT YOUR SOLE RISK.
                    THE SITE IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS
                    AVAILABLE&quot; BASIS. IGIV AND ITS AFFILIATES EXPRESSLY
                    DISCLAIM AND EXCLUDE, TO THE FULLEST EXTENT PERMITTED BY
                    APPLICABLE LAW, ALL WARRANTIES, CONDITIONS AND
                    REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR
                    STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, TITLE AND NON-INFRINGEMENT.
                  </span>
                </li>
                <li>
                  <span>
                    IGIV AND ITS AFFILIATES MAKE NO WARRANTY OR CONDITION THAT
                    (I) THE SITE AND SERVICES WILL MEET YOUR REQUIREMENTS, (II)
                    THE SITE AND SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE,
                    OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM
                    THE USE OF THE SITE OR SERVICE WILL BE ACCURATE OR RELIABLE,
                    OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION,
                    OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE
                    SITE WILL MEET YOUR EXPECTATIONS.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Limitation of Liability</span>
              </h2>
              <ol start="32">
                <li>
                  <span>
                    NEITHER WE NOR OUR OFFICERS, DIRECTORS, EMPLOYEES AND
                    AGENTS, WILL BE LIABLE TO YOU FOR SPECIAL, CONSEQUENTIAL,
                    INDIRECT, PUNITIVE, EXEMPLARY OR INCIDENTAL DAMAGES
                    (INCLUDING LOST REVENUES OR PROFITS, OR LOSS OF GOODWILL),
                    REGARDLESS OF THE CAUSE, LEGAL THEORY, OR CAUSE OF ACTION,
                    EVEN IF WE HAVE BEEN ADVISED OF THE LIKELIHOOD THEREOF. OUR
                    AGGREGATE LIABILITY, TOGETHER WITH THE LIABILITY OF OUR
                    OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, ARISING OUT OF
                    THESE TERMS AND YOUR USE OF THE SITE (WHEN AGGREGATED WITH
                    ALL OTHER CLAIMS AGAINST US ARISING OUT OF THIS AGREEMENT
                    AND YOUR USE OF THE SITE), REGARDLESS OF THE TYPE OF
                    CLAIM(S) OR THE NATURE OF THE CAUSE(S) OF ACTION, WILL NOT
                    EXCEED THE GREATER OF (I) ONE HUNDRED U.S. DOLLARS ($100) OR
                    (II) THE NET AMOUNT WE HAVE BEEN PAID FROM TRANSACTION FEES
                    ARISING FROM TRANSACTIONS YOU HAVE MADE THROUGH THE SITE IN
                    THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE EVENT
                    GIVING RISE TO YOUR CLAIM(S). YOU ACKNOWLEDGE THAT THE
                    FOREGOING LIMITATIONS ARE AN ESSENTIAL BASIS OF THE BARGAIN
                    WE HAVE REACHED AND THAT THEY WILL APPLY NOTWITHSTANDING ANY
                    FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Release of Claims; Indemnification</span>
              </h2>
              <ol start="33">
                <li>
                  <span>
                    TO THE MAXIMUM EXTENT PERMISSIBLE BY LAW, YOU AGREE TO
                    RELEASE, INDEMNIFY ON DEMAND AND HOLD IGIV AND ITS
                    AFFILIATES AND THEIR RESPECTIVE OFFICERS, EMPLOYEES,
                    DIRECTORS AND AGENTS HARMLESS FROM ANY FROM ANY AND ALL
                    LOSSES, DAMAGES, EXPENSES, INCLUDING REASONABLE
                    ATTORNEYS&#39; FEES, COSTS, AWARDS, FINES, DAMAGES, RIGHTS,
                    CLAIMS, ACTIONS OF ANY KIND AND INJURY (INCLUDING DEATH)
                    ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF THE
                    SITE, OUR SERVICES, INCLUDING, BUT NOT LIMITED TO, ANY
                    DONATION OR CAMPAIGN, ANY CONTENT UPLOADED BY ANY USER, ANY
                    DONATION OR CAMPAIGN, YOUR ACCESS TO THE SITE AND SERVICES,
                    YOUR VIOLATION OF THESE TERMS OR YOUR VIOLATION OF ANY
                    RIGHTS OF ANOTHER. IF YOU ARE A CALIFORNIA RESIDENT, YOU
                    WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS:
                    <br />
                    &quot;A general release does not extend to claims which the
                    creditor does not know or suspect to exist in his favor at
                    the time of executing the release, which if known by him
                    must have materially affected his settlement with the
                    debtor.&quot;
                    <br />
                    IF YOU ARE A RESIDENT OF ANOTHER JURISDICTION&mdash;IN OR
                    OUTSIDE OF THE UNITED STATES&mdash;YOU WAIVE ANY COMPARABLE
                    STATUTE OR DOCTRINE.
                  </span>
                </li>
              </ol>
              <h2>
                <span>Types of Beneficiaries on the Platform</span>
              </h2>
              <ol start="34">
                <li>
                  <span>
                    If registering on the IGIV platform you will have the option
                    to select a beneficiary by going directly to their assigned
                    URL.
                  </span>
                </li>
              </ol>
              <ol start="35">
                <li>
                  <span>
                    IGIV does not accept transactions for organizations that are
                    not registered within the IGIV Platform.
                  </span>
                </li>
              </ol>
              <ol start="36">
                <li>
                  <span>Direct Beneficiaries.</span>
                  <span>&nbsp; Funds </span>
                  <span>
                    will be automatically deposited, less our Fees to the
                    Beneficiaries account or in some instances our Fees may be
                    billed separately. IGIV is not responsible for the use of
                    the funds by a Beneficiary. Direct beneficiaries may have
                    their own rules and codes of conduct and by fundraising or
                    registering for one of these beneficiaries you are agreeing
                    to abide by their conditions. At the request of the
                    Beneficiary pages may be taken down, registrations and
                    donations refunded. IGIV is not responsible for ensuring
                    your conduct is compliant.
                  </span>
                </li>
              </ol>
              <h2>
                <span></span>
              </h2>
              <RefundTerms />
              <h2>
                <span>Suspension and Termination of Account</span>
              </h2>
              <ol start="40">
                <li>
                  <span>
                    You agree that IGIV, in its sole discretion, may suspend or
                    terminate your account (or any part thereof) or use of the
                    Site and remove and discard any content within the Site
                    under reasonable circumstances, including, without
                    limitation, for lack of use or if IGIV believes that you
                    have violated or acted inconsistently with the letter or
                    spirit of these Terms. Any suspected fraudulent, abusive or
                    illegal activity that may be grounds for termination of your
                    use of the Site, may be referred to appropriate law
                    enforcement authorities. You agree that any termination of
                    your access to the Site under any provision of these Terms
                    may be effected without prior notice, and acknowledge and
                    agree that IGIV may immediately deactivate or delete your
                    account and all related information and files in your
                    account and/or bar any further access to such files or the
                    Site where such deactivation or deletion is permitted under
                    these Terms. Further, you agree that IGIV will not be liable
                    to you or any third party for any termination of your access
                    to the Site.
                  </span>
                </li>
              </ol>
              <h2>
                <span></span>
              </h2>
              <h2>
                <span></span>
              </h2>
              <h2>
                <span>User Registration</span>
              </h2>
              <ol start="41">
                <li>
                  <span>
                    You may use the platform anonymously without registering.
                    When you do this some information such as your IP address,
                    location and site usage may be collected.
                  </span>
                </li>
                <li>
                  <span>
                    You may also register Your details and be added to the
                    Platform database.
                    <br />
                  </span>
                  <span>
                    Acceptance of your registration and the continuation of it
                    will be subject to these Terms and Conditions.
                  </span>
                </li>
              </ol>
              <h2>
                <span>General Terms</span>
              </h2>
              <ol start="43">
                <li>
                  <span>Attorney&rsquo;s Fees.</span>
                  <span>
                    &nbsp;If a dispute arises out of these Terms or your use of
                    the Site then the prevailing party in any litigation will be
                    entitled to recover all costs and expenses (including
                    reasonable attorney&#39;s fees) incurred as a result of that
                    litigation
                  </span>
                </li>
                <li>
                  <span>No Joint Venture; Independent Contractors.</span>
                  <span>
                    &nbsp;These Terms do not create, and shall not be construed
                    to create, a joint venture, partnership, or other formal
                    business relationship between you and us. At all times we
                    shall remain independent contractors with respect to one
                    another.
                  </span>
                </li>
                <li>
                  <span>No Assignment.</span>
                  <span>
                    &nbsp;You have neither the right nor the power to assign
                    your rights under these Terms. Any purported assignment of
                    your rights under these Terms will be null and void.
                  </span>
                </li>
                <li>
                  <span>Severability.</span>
                  <span>
                    &nbsp;If any provision of the Terms is found to be
                    unenforceable, invalid, or illegal by a court of competent
                    jurisdiction, this finding shall not render any other
                    provision of the Terms unenforceable, invalid, or illegal.
                    We both agree that the court will have the authority to
                    modify or replace the unenforceable, invalid, or illegal
                    provision with a valid and enforceable provision that most
                    closely represents our intentions with respect to the
                    invalid, illegal, or unenforceable provision.
                  </span>
                </li>
                <li>
                  <span>No Waiver.</span>
                  <span>
                    &nbsp;Any delay on your part, or on our part, to exercise a
                    right or power granted under these Terms will not be
                    construed as a waiver of such right or power. All waivers
                    must be in writing and a waiver of any particular breach
                    will not be construed as a waiver of any other breach, or
                    any succeeding breach.
                  </span>
                </li>
                <li>
                  <span>Survival.</span>
                  <span>
                    &nbsp;The provisions of the sections of these Terms titled
                    &quot;Intellectual Property,&quot; &quot;Liability,&quot;
                    and &quot;General&quot; will survive termination.
                  </span>
                </li>
                <li>
                  <span>Headers.</span>
                  <span>
                    &nbsp;The headers contained within these Terms are for
                    convenience of reference only. They should not be
                    interpreted to modify the plain meaning of the various
                    provisions of these Terms.
                  </span>
                </li>
                <li>
                  <span>Entire Agreement.</span>
                  <span>
                    &nbsp;These Terms contain the entire agreement between you
                    and us, they are a complete integration of our agreement and
                    supersede and displace any earlier or contemporaneous
                    written or oral negotiations, statements, or agreements
                    purporting to deal with the subject matter hereof.
                  </span>
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
        <div className="py-5"></div>
      </div>
    </AppLayout>
  )
}

export default Terms
