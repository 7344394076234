import routes from 'config/routes'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export function Footer() {
  return (
    <div className="footer">
      <div className="py-5 px-3 bg-dark text-white-50">
        <Container>
          <Row className="justify-content-between">
            <Col md={5}>
              <h5>About</h5>
              <p>
                We help organizations in your community raise the funds they
                need to do what they do best. We take the hassle out of
                fundraising for everyone involved with a secure platform for
                providing support and rewarding supporters.
              </p>
            </Col>
            <Col md={5}>
              <Row>
                <Col className="d-flex flex-column mb-4">
                  <h5>LINKS</h5>
                  <Link to={routes.terms}>Terms and Conditions</Link>
                  <Link to={routes.privacy}>Privacy Policy</Link>
                  <Link to={routes.refunds}>Refunds</Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>Contact</h5>
                  <p>
                    You can reach us at{' '}
                    <a
                      href="mailto:support@igiv.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      support@igiv.org
                    </a>
                    .
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="p-3 footer-copyright text-center text-muted">
        &copy; {new Date().getFullYear()} iGiv
      </div>
    </div>
  )
}

export default Footer
