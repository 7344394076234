import PropTypes from 'prop-types'
import React from 'react'
import { Card, ProgressBar } from 'react-bootstrap'
import { FormattedNumber } from 'react-intl'
import { toPrice } from 'utils'

export function FundraiserCard({ fundraiser, children }) {
  const logo = fundraiser.logo || fundraiser.stock_logo
  const cover = fundraiser.cover || fundraiser.stock_cover

  return (
    <Card>
      <div
        className='fundraiser-cover bg-secondary rounded-top'
        style={{
          backgroundImage: `url(${cover})`,
        }}
      />
      <div className='fundraiser-logo bg-secondary d-flex text-primary justify-content-center align-items-center mx-auto mb-3'>
        {!!logo && (
          <div>
            <img
              src={logo}
              className='w-100 h-100'
              alt={`${fundraiser.title} logo`}
            />
          </div>
        )}
      </div>
      <Card.Body className='pt-0'>
        <h1 className='h3'>{fundraiser.title}</h1>
        <div className='my-3'>
          <ProgressBar now={(fundraiser.raised / fundraiser.goal) * 100} />
          <p className='lead font-weight-bold text-muted'>
            $
            <FormattedNumber
              value={toPrice(fundraiser.raised)}
              maximumFractionDigits={2}
              minimumFractionDigits={parseInt(fundraiser.raised/100) === fundraiser.raised/100 ? 0 : 2}
            />{' '}
            of $
            <FormattedNumber
              value={toPrice(fundraiser.goal)}
              maximumFractionDigits={2}
              minimumFractionDigits={parseInt(fundraiser.goal/100) === fundraiser.goal/100 ? 0 : 2}
            />
          </p>
        </div>
        {children}
      </Card.Body>
    </Card>
  )
}

FundraiserCard.propTypes = {
  fundraiser: PropTypes.object.isRequired,
}

export default FundraiserCard
