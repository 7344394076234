import { AppLayout } from 'layouts'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export function Privacy() {
  return (
    <AppLayout>
      <div className="bg-white">
        <Container className="pt-5">
          <Row>
            <Col lg={8} md={10} className='mx-auto'>
              <h1>Privacy Policy</h1>
              <p>
                <span className="font-italic">
                  Last revised on: 23 February 2021
                </span>
              </p>
              <h2>What Information Do We Collect?</h2>
              <p>
                <span>
                  When you visit our Web site you may provide us with two types
                  of information: personal information you knowingly choose to
                  disclose that is collected on an individual basis and Web site
                  use information collected on an aggregate basis as you and
                  others browse our Web site.
                </span>
              </p>
              <h2>Personal Information You Choose to Provide</h2>
              <p>
                <span>
                  We may request that you voluntarily supply us with personal
                  information, including your email address, postal address,
                  home or work telephone number and other personal information
                  for such purposes as correspondence, placing an order, or
                  making a donation. &nbsp;If you choose to correspond with us
                  through email, we may retain the content of your email
                  messages together with your email address and our responses.
                  We provide the same protections for these electronic
                  communications that we employ in the maintenance of
                  information received by mail and telephone.
                </span>
              </p>
              <h2>Web Site Use Information</h2>
              <p>
                <span>
                  Similar to other commercial Web sites, our Web site may
                  utilize a standard technology called &#39;cookies&#39; (see
                  explanation below, &#39;What Are Cookies?&#39;) and Web server
                  logs to collect information about how our Web site is used.
                  Information gathered through cookies and Web server logs may
                  include the date and time of visits, the pages viewed, time
                  spent at our Web site, and the Web sites visited just before
                  and just after our Web site. This information is collected on
                  an aggregate basis. None of this information is associated
                  with you as an individual.
                </span>
              </p>
              <h2>How Do We Use the Information That You Provide to Us?</h2>
              <p>
                <span>
                  Broadly speaking, we use personal information for purposes of
                  administering our business activities, providing customer
                  service and making available other products and services to
                  our customers and prospective customers. Occasionally, we may
                  also use the information we collect to notify you about
                  important changes to our Web site, new services and special
                  offers we think you will find valuable. The lists used to send
                  you product and service offers are developed and managed under
                  our traditional corporate standards designed to safeguard the
                  security and privacy of our customers&#39; personal
                  information. You are allowed at any time to notify us of your
                  desire not to receive these offers.
                </span>
              </p>
              <h2>What Are Cookies?</h2>
              <p>
                <span>
                  Cookies are a feature of Web browser software that allows Web
                  servers to recognize the computer used to access a Web site.
                  Cookies are small pieces of data that are stored by a
                  user&#39;s Web browser on the user&#39;s hard drive. Cookies
                  can remember what information a user accesses on one Web page
                  to simplify subsequent interactions with that Web site by the
                  same user or to use the information to streamline the
                  user&#39;s transactions on related Web pages. This makes it
                  easier for a user to move from Web page to Web page and to
                  complete commercial transactions over the Internet. Cookies
                  should make your online experience easier and more
                  personalized.
                </span>
              </p>
              <h2>How Do We Use Information We Collect from Cookies?</h2>
              <p>
                <span>
                  &nbsp;We use Web site browser software tools such as cookies
                  and Web server logs to gather information about our Web site
                  users&#39; browsing activities, in order to constantly improve
                  our Web site and better serve our customers. This information
                  assists us to design and arrange our Web pages in the most
                  user-friendly manner and to continually improve our Web site
                  to better meet the needs of our customers and prospective
                  customers. Cookies help us collect important business and
                  technical statistics. The information in the cookies lets us
                  trace the paths followed by users to our Web site as they move
                  from one page to another. Web server logs allow us to count
                  how many people visit our Web site and evaluate our Web
                  site&#39;s visitor capacity. We do not use these technologies
                  to capture your individual email address or any personally
                  identifying information about you.
                </span>
              </p>
              <h2>Notice of New Services and Changes</h2>
              <p>
                <span>
                  Occasionally, we may use the information we collect to notify
                  you about important changes to our Web site, new services and
                  special offers we think you will find valuable. As our client,
                  you will be given the opportunity to notify us of your desire
                  not to receive these offers by clicking on a response box when
                  you receive such an offer or by sending us an email request.
                </span>
              </p>
              <h2>How Do We Secure Information Transmissions?</h2>
              <p>
                <span>
                  All information shared through our website is secured with
                  SSL. This ensures any information you send through our website
                  is encrypted and cannot be viewed by anybody else. Payments
                  are handled by
                  <a href="https://stripe.com">Stripe</a> and any payment
                  information you share is also encrypted. Email that you may
                  send to us may not be secure unless we advise you that
                  security measures will be in place prior to your transmitting
                  the information. For that reason, we ask that you do not send
                  confidential information such as Social Security or account
                  numbers to us through an unsecured email.
                </span>
              </p>
              <h2>How Do We Protect Your Information?</h2>
              <p className="font-weight-bold">Information Security</p>
              <p>
                We utilize encryption/security software to safeguard the
                confidentiality of personal information we collect from
                unauthorized access or disclosure and accidental loss,
                alteration or destruction.
              </p>
              <p className="font-weight-bold">
                Evaluation of Information Protection Practices
              </p>
              <p>
                Periodically, our operations and business practices are reviewed
                for compliance with corporate policies and procedures governing
                the security, confidentiality and quality of our information.
              </p>
              <p className="font-weight-bold">
                Employee Access, Training and Expectations
              </p>
              <p>
                Our corporate values, ethical standards, policies and practices
                are committed to the protection of customer information. In
                general, our business practices limit employee access to
                confidential information, and limit the use and disclosure of
                such information to authorized persons, processes and
                transactions.
              </p>
              <h2>How Can You Access and Correct Your Information?</h2>
              <p>
                <span>
                  You may request access to all your personally identifiable
                  information that we collect online and maintain in our
                  database by emailing us using the contact form.
                </span>
              </p>
              <h2>Do We Disclose Information to Outside Parties?</h2>
              <p>
                <span>
                  We may provide aggregate and detailed information about our
                  customers, sales, Web site traffic patterns and related Web
                  site information to our vendors, affiliates or reputable third
                  parties, but this information will not include personally
                  identifying data unless it is expressly for the purpose of
                  fulfilling an order placed by the customer or otherwise
                  provided in this Privacy Policy.
                </span>
              </p>
              <h2>What About Legally Compelled Disclosure of Information?</h2>
              <p>
                <span>
                  We may disclose information when legally compelled to do so,
                  in other words, when we, in good faith, believe that the law
                  requires it or for the protection of our legal rights.
                </span>
              </p>
              <h2>Permission to Use of Materials</h2>
              <p>
                <span>
                  The right to download and store or output the materials in our
                  site is granted for the user&#39;s personal use only, and
                  materials may not be reproduced in any edited form. Any other
                  reproduction, transmission, performance, display or editing of
                  these materials by any means mechanical or electronic without
                  the express written permission of the Company is strictly
                  prohibited. Users wishing to obtain permission to reprint or
                  reproduce any materials appearing on this site may contact us
                  directly.
                </span>
              </p>
            </Col>
          </Row>
        </Container>
        <div className="py-5"></div>
      </div>
    </AppLayout>
  )
}

export default Privacy
