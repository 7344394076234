import { compile } from 'path-to-regexp'

export const formatRoute = (route, params = {}) => compile(route)(params)

const routes = {
  homepage: '/',
  terms: '/terms/',
  privacy: '/privacy/',
  refunds: '/refunds/',
}

export default routes
