import { RefundTerms } from 'components'
import { AppLayout } from 'layouts'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export function Refunds() {
  return (
    <AppLayout>
      <div className="bg-white">
        <Container className="pt-5">
          <Row>
            <Col lg={8} md={10} className="mx-auto">
              <h1>Refunds</h1>
              <p>
                <span className="font-italic">
                  Last revised on: 23 February 2021
                </span>
              </p>
              <p>
                <span>
                  Each user that makes a purchase through the IGIV platform has
                  agreed to the following terms of use:
                </span>
              </p>
              <Row>
                <Col className="p-3 border border-warning rounded-lg mx-auto">
                  <RefundTerms />
                </Col>
              </Row>
              <p className="pt-3">
                <span>
                  Unfortunately, every now and then people make mistakes when
                  making a purchase through the IGIV platform. With all
                  transactions on the IGIV platform we issue a receipt with all
                  appropriate contact information. We have to be extra diligent
                  around processing refunds as this is open to fraudulent
                  behavior. &nbsp;We invite all IGIV users that may feel that
                  their purchase was made in error to follow the instructions in
                  our Refund Policy carefully and to contact IGIV immediately
                  using the appropriate online contact forms. &nbsp; As with
                  many online payment platforms, IGIV gets charged for the
                  initial transaction by payment gateways and banks etc. The
                  cost of this transaction makes up part of the refund fee along
                  with the internal cost of processing the refund. These costs
                  are then passed on to the person who made the initial
                  transaction. We assess each refund request carefully before
                  passing on these costs.
                </span>
              </p>
              <p>
                <span>PLEASE NOTE:</span>
                <span>
                  &nbsp;We are unable to process refund requests after funds
                  have been remitted directly to the beneficiary (See Refund
                  Policy Section 37 of Terms and conditions. &nbsp; Please
                  coordinate with the organization directly to request a refund.
                </span>
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
              <p>
                <span></span>
              </p>
              <p>
                <span></span>
              </p>
            </Col>
          </Row>
        </Container>
        <div className="py-5"></div>
      </div>
    </AppLayout>
  )
}

export default Refunds
