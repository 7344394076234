import React from 'react'

const serialize = (obj) => Object.keys(obj).map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&')

export function MailTo({ title, recipient, options }) {
  return (
    <a
      href={`mailto:${recipient}?${serialize(options)}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {title}
    </a>
  )
}

export default MailTo
