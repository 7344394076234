import imgClassroom from 'images/classroom.jpg'
import fullLogo from 'images/igiv-logo-full.png'
import screenshotLeaderboard from 'images/igiv-screenshot-leaderboard.png'
import screenshotSupport from 'images/igiv-screenshot-support.png'
import imgMarchingBand from 'images/marching-band.jpg'
import imgSoccerKids from 'images/soccer-kids.jpg'
import imgGirlSoftball from 'images/softball.jpg'
import tripBoomerangLogo from 'images/tripBoomerang.png'
import screenshotTripBoomerang from 'images/tripboomerang-screenshot.png'
import { AppLayout } from 'layouts'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './index.scss'

export function Main() {
  return (
    <AppLayout>
      <div className="bg-white">
        <div className="py-5 d-none d-sm-block"></div>
        <div className="py-5"></div>
        <Container>
          <Row>
            <Col md={6} className="d-flex align-items-center">
              <div className="flex-grow-1 text-center">
                <img src={fullLogo} alt="igiv logo" width="300" />
                <h1 className="sr-only">igiv</h1>
              </div>
            </Col>
            <Col>
              <h2>Fundraising made easy</h2>
              <p className="lead">
                Take the hassle out of fundraising with igiv. Take orders
                online, deliver rewards instantly, and make the most of your
                fundraising efforts.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="py-5 d-none d-sm-block"></div>
        <div className="py-5"></div>
      </div>
      <div className="bg-primary text-white">
        <div className="py-5"></div>
        <Container>
          <Row className="mb-5">
            <Col md={6} className="d-flex align-items-center mb-5 mb-sm-0">
              <div>
                <h2>Why use igiv?</h2>
                <p className="lead">
                  We're changing the way fundraisers are done. Our approach is
                  simple - reduce the amount of work required, provide valuable
                  rewards to supporters, and make sure as much money as possible
                  goes to the program raising funds.
                </p>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center mb-5 mb-sm-0">
              <img
                className="w-100"
                src={screenshotSupport}
                alt="screenshot of the igiv page to support a fundraiser"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="d-flex align-items-center mb-5 mb-sm-0">
              <ul className="fa-ul font-weight-bold">
                <li>
                  <span className="fa-li">
                    <i className="fad fa-sparkles text-stripe-secondary fa-2x" />
                  </span>
                  <h5 className="ml-2">Digital-first</h5>
                  <p className="lead">
                    Simplify your fundraising efforts by taking orders online.
                    Digital orders are delivered immediately.
                  </p>
                </li>
                <li>
                  <span className="fa-li">
                    <i className="fad fa-sparkles text-stripe-secondary fa-2x d2" />
                  </span>
                  <h5 className="ml-2">Secure</h5>
                  <p className="lead">
                    Customer information and payments are secured with
                    bank-level encryption. Payments are handled by{' '}
                    <a
                      href="https://stripe.com"
                      className="btn-link text-white font-weight-bold text-underline"
                    >
                      Stripe
                    </a>{' '}
                    - the industry leader in online payment processing.
                  </p>
                </li>
                <li>
                  <span className="fa-li">
                    <i className="fad fa-sparkles text-stripe-secondary fa-2x d2" />
                  </span>
                  <h5 className="ml-2">Simple</h5>
                  <p className="lead">
                    Share your fundraiser via email, text, or social media – the
                    possibilites are endless. Use our leaderboard to view live
                    updates and see how your fundraiser is doing.
                  </p>
                </li>
              </ul>
            </Col>
            <Col
              md={6}
              className="order-md-first d-flex align-items-center mb-5 mb-sm-0"
            >
              <img
                className="w-100"
                src={screenshotLeaderboard}
                alt="screenshot of the igiv page to support a fundraiser"
              />
            </Col>
          </Row>
        </Container>
        <div className="py-5"></div>
      </div>
      <div className="bg-white">
        <div className="py-5"></div>
        <Container>
          <Row className="text-center">
            <Col md={8} lg={6} className="mx-auto">
              <h2>Reward Your Supporters</h2>
            </Col>
          </Row>
          <div className="my-5" />
          <Row>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center mb-5 mb-md-0"
            >
              <img
                src={tripBoomerangLogo}
                width="300"
                alt="tripBoomerang logo"
              />
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <p className="lead">
                As a thank you for their loyalty, give your supporters something
                valuable that they'll actually use. Our tripBoomerang Travel
                eCode costs $25 and is worth $50 towards hotels, flights, rental
                cars, and more when booked through{' '}
                <a href="https://tripboomerang.com">tripBoomerang.com</a>.{' '}
              </p>
            </Col>
          </Row>
        </Container>
        <div className="py-3"></div>
        <div className='text-center'>
          <img src={screenshotTripBoomerang} width='80%' style={{maxWidth: 800}} alt='tripBoomerang website homepage screenshot'/>
        </div>
      </div>
      <div className="bg-primary text-white">
        <div className="py-5"></div>
        <Container>
          <Row>
            <Col md={6} className="d-flex align-items-center mb-4 mb-sm-0">
              <div>
                <h2>We can't do it without you</h2>
                <p className="lead">
                  Organizations in your community need your help to do what they
                  do best. Without the support you provide, many of these
                  programs would not survive.
                </p>
                <p className="lead">
                  At igiv, our goal is to simplify the fundraising process for
                  both the organizations raising funds and individuals who
                  support them.
                </p>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <img
                  className="homepage-fundraiser-example"
                  width="250"
                  src={imgClassroom}
                  alt="kids in a classroom"
                />
                <img
                  className="homepage-fundraiser-example"
                  width="250"
                  src={imgSoccerKids}
                  alt="soccer team playing"
                />
                <img
                  className="homepage-fundraiser-example"
                  width="250"
                  src={imgMarchingBand}
                  alt="marching band members sitting on the ground"
                />
                <img
                  className="homepage-fundraiser-example"
                  width="250"
                  src={imgGirlSoftball}
                  alt="girl playing softball"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="py-5"></div>
      </div>
    </AppLayout>
  )
}

export default Main
