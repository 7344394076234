import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'

export function LoadingButton({ loading, children, ...btnProps }) {
  return (
    <Button disabled={loading} {...btnProps}>
      {children}
      {loading && <i className='fas fa-spinner fa-spin fa-end text-white ml-1' />}
    </Button>
  )
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
}

LoadingButton.defaultProps = {
  loading: false,
}

export default LoadingButton
